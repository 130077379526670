ul, ol {
    margin: 0 0 15px;
	padding-left: 18px;
}

p {
    margin: 0 0 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
    text-decoration: none;
    outline: 0;
    transition: 0.3s;
	color: var(--tg-theme-link-color);

    &:hover, &:active {
		text-decoration: none;
    }

    &[disabled] {
		pointer-events: none;
    }
}

.text-delimiter {
	position: relative;
	text-align: center;
	line-height: 1;
	margin-bottom: 15px;
	display: block;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		margin-top: 1px;
		height: 1px;
		background: var(--tg-theme-secondary-bg-color);
		z-index: 1;
	}

	span {
		display: inline-block;
		background: var(--tg-theme-bg-color);
		position: relative;
		z-index: 2;
		padding: 0 8px;
		color: var(--tg-theme-subtitle-text-color);
	}
}
