.BottomSheet {
	z-index: 100 !important;

	&__draggable {
		max-width: 480px !important;
	}

	&__window-wrap {
		background-color: var(--tg-theme-secondary-bg-color) !important;
		padding: 62px 16px 16px;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		color: var(--tg-theme-text-color);
	}

	&__drag-indicator-wrap {
		display: none !important;
	}

	&Close {
		color: var(--tg-theme-subtitle-text-color);
		position: absolute;
		right: 16px;
    	top: 16px;
		width: 30px;
		height: 30px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #7676801f;
		border-radius: 100%;

		svg {
			width: 12px;
			height: 12px;
			display: block;
		}
	}

	&Title {
		color: var(--tg-theme-text-color);
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 16px;
		font-size: 17px;
		line-height: 1.76;
		font-weight: 600;
		text-align: center;
		white-space: nowrap;
	}
}
