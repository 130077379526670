input, select, textarea {
    outline: 0;
    font-family: var(--fontMain);
    font-size: 14px;
    font-weight: 400;

    @media (max-width: 767px) {
        font-size: 16px;
    }
}

label {
    cursor: pointer;
}

.input {
	background: var(--another-grey-transparent);
	border: 0;
	min-height: 44px;
	width: 100%;
	font-size: 16px;
	position: relative;
	z-index: 10;
	box-shadow: var(--box-shadow);
	border-radius: 10px;
	padding: 1px 1.0625rem 0;
    line-height: 44px;
    color: var(--tg-theme-text-color);
	cursor: pointer;
	margin-bottom: 15px;

	&::placeholder {
		color: var(--tg-theme-hint-color);
	}

	&--grey {
		background: var(--tg-theme-secondary-bg-color);
	}

	&--center {
		text-align: center;
	}
}

.form-check {
	display: flex;
	align-items: flex-start;
    min-height: 24px;
    margin-bottom: 12px;

	input {
		border-radius: 4px;
		float: left;
		flex: 0 0 16px;
		min-width: 16px;
		height: 16px;
		vertical-align: top;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		border: 1px solid var(--tg-theme-hint-color);
		margin-right: 8px;
		appearance: none;

		&:checked {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
			background-color: #0d6efd;
			border-color: #0d6efd;
		}
	}
}
